import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import NewsSectionBlock from '../components/NewsSectionBlock'
import SEO from '../components/seo'
import Typography from '../components/Common/Typography'

import * as s from '../pages/news.module.scss'

const AutodeskServiceProvider: React.FC = () => {
  return (
    <>
      <SEO
        title="Autodesk Service Provider"
        description="Looking for Autodesk services? Signax.io is your trusted Autodesk Service Provider. We offer expert guidance, software solutions, and training to maximize your design and engineering projects success."
      />
      <NewsSectionBlock date="21.12.2022">
        <Typography variant="h1" color="blue">
          SIGNAX becomes Autodesk Service Provider in the MENA region
        </Typography>
        <Typography variant="body1">
          SIGNAX and Autodesk have signed an agreement making SIGNAX the Service
          Provider Partner for Autodesk in the MENA region. This means that
          Autodesk Corporation appreciates and recognizes the company expertise,
          the level of services and product implementation approach that SIGNAX
          provides for its customers in the region. By this nomination Autodesk
          admits that SIGNAX delivers the highest quality services which
          completely meet the customers’ needs throughout the development of
          ambitious construction projects.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/news/2022-12-21-autodesk-service-provider/image-1.png"
            alt="SIGNAX becomes Autodesk Service Provider in the MENA region"
            title="SIGNAX becomes Autodesk Service Provider in the MENA region"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          Working under the Service Provider agreement, SIGNAX will cover a full
          range of services for the implementation and adoption of cloud
          products (Autodesk Construction Cloud). We will focus on
          digitalisation of construction processes and interaction on the
          construction site. If required, SIGNAX will definitely supplement
          Autodesk solutions with SIGNAX product portfolio, which allows real
          estate developers and their contractors digitalise their business
          processes and significantly increase project efficiency. Other types
          of services: Common Data Environment (CDE) Implementation; On-site BIM
          support/ BIM field coordination, BIM Consulting and Management; BIM
          modelling.
        </Typography>
        <Typography variant="body1">
          SIGNAX is proud to receive this honorary status from Autodesk. SIGNAX
          experts are confident that the collaboration within the framework
          program will enhance the quality of services, and will give our
          employees opportunity to do regular training and certification to the
          top level standards. Some implementation projects can be carried out
          by SIGNAX and Autodesk joint team, using the best resources and
          practices. Customers will obviously benefit from such approach
          achieving project success and their goals while the construction
          digital transformation.
        </Typography>
        <Typography variant="h2" color="blue">
          About SIGNAX
        </Typography>
        <Typography variant="body1">
          SIGNAX is a global IT company with offices in Dubai (UAE) and Boston
          (USA). The company unites construction experts with thorough
          understanding of the industry challenges today, and makes the
          innovations for the future. SIGNAX main goal is to bring the power of
          digital technologies and BIM to the construction increasing its
          efficiency. SIGNAX has 15+ implementations in Real Estate and
          industrial projects, more than 50 iconic buildings and 3000000 sq.m.
          GBA built using SIGNAX solutions. The success of our customers is main
          priority for the company.
        </Typography>
        <Typography variant="h2" color="blue">
          About Autodesk
        </Typography>
        <Typography variant="body1">
          Autodesk helps people imagine, design and create a better world.
          Everyone—from design professionals, engineers and architects to
          digital artists, students and hobbyists—uses Autodesk software to
          unlock their creativity and solve important challenges.
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default AutodeskServiceProvider
